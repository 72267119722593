<template>
  <div class="splash" data-name="splash-02-a">
    <div class="absolute inset-x-0 top-0 z-20 px-4 lg:pt-8 lg:px-8 xl:px-0">
      <slot />
    </div>
    <div class="splash__content">
      <video
        v-if="data.media === 'video'"
        playsinline="true"
        preload="auto"
        loop="true"
        autoplay
        muted
        class="object-cover absolute left-0 w-full h-full top-0 brightness-50"
      >
        <source
          v-if="data.video.extension === 'webm'"
          type="video/webm"
          :src="data?.video?.url"
        >
        <source
          v-if="data.video.extension === 'mp4'"
          type="video/mp4"
          :src="data?.video?.url"
        >
      </video>

      <BaseImage
        v-else
        :src="data?.image?.original?.src"
        preload
        class="absolute inset-0 -z-10 h-full w-full object-cover"
        alt="Hero background"
        quality="50"
      />

      <div class="container">
        <div class="py-16 grid grid-cols-12 place-items-center w-full">
          <div class="col-span-full max-w-3xl text-center lg:mt-0">
            <BaseTitle
              level="h1"
              variant="white"
            >
              <span v-html="computedTitle" />
            </BaseTitle>
            <p class="text-brand-white my-8 text-xl">
              {{ data?.subtitle }}
            </p>
          </div>
        </div>

        <CarIntervalPicker class="mb-6" />
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue';
import BaseTitle from '~/components/BaseTitle.vue';
import CarIntervalPicker from './pickers/CarIntervalPicker.vue';
import BaseImage from './BaseImage.vue';

const props = defineProps({
  data: {
    required: true,
    type: Object,
  },
});

const computedTitle = computed(() => {
  const title = props.data?.title || '';

  return title.replace('|', '<br>');
});
</script>

<style scoped>
.splash {
  @apply lg:p-8 p-0 relative z-20;
  &__content {
    @apply relative xl:isolate xl:overflow-hidden pt-14 lg:px-10 px-6 min-h-screen xl:min-h-[50rem] ;
    @apply flex flex-col justify-center lg:items-center lg:rounded-xl;
  }
}
</style>
