<template>
  <div class="w-full relative z-20 lg:p-0 p-4">
    <ul
      v-if="intervalPickerOptions.length > 1"
      class="grid w-full gap-6 max-w-md"
      :style="{ gridTemplateColumns: computedCols }"
    >
      <li v-for="option in intervalPickerOptions" :key="option.value">
        <input
          :id="option.value"
          v-model="selectedOption"
          type="radio"
          :value="option.value"
          class="hidden peer"
        >
        <label :for="option.value" class="text-center flex-shrink-0 inline-flex items-center justify-between w-full px-6 py-2 text-brand-white bg-transparent rounded-lg cursor-pointer peer-checked:border peer-checked:border-brand-white h-full">
          <span class="w-full text-base font-semibold">{{ option.label }}</span>
        </label>
      </li>
    </ul>

    <CarIntervalPickerSubscription v-if="selectedOption === 'subscription'" />
    <CarIntervalPickerRent v-if="selectedOption === 'rent'" />
  </div>
</template>

<script setup lang="ts">
import { ref, computed, defineAsyncComponent } from 'vue';

const CarIntervalPickerSubscription = defineAsyncComponent(() => import('./CarIntervalPickerSubscription.vue'));
const CarIntervalPickerRent = defineAsyncComponent(() => import('./CarIntervalPickerRent.vue'));

const props = withDefaults(defineProps<{
  showSubscription?: boolean,
  showRental?: boolean,
}>(), {
  showRental: false,
  showSubscription: true,
});

type PickerType = 'subscription' | 'rent';
type PickerOption = {
  value: PickerType,
  label: string,
};

const selectedOption = ref<PickerType>('subscription');
const intervalPickerOptions = computed(() => [
  props.showRental && { value: 'rent', label: 'Alquila por días' },
  props.showSubscription && { value: 'subscription', label: 'Suscríbete' },
].filter(Boolean) as PickerOption[]);

const computedCols = computed(() => `repeat(${intervalPickerOptions.value.length}, minmax(0, 1fr))`);
</script>
